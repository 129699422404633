import './App.css';
import axios from 'axios';
import { Button } from '@mui/material';
import { Card, CardMedia, CardActions, CircularProgress } from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { LoadingButton } from '@mui/lab';
import moment from 'moment';
import download from './download';
import SaveIcon from '@mui/icons-material/Save';
import SettingsIcon from '@mui/icons-material/Settings';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { scryRenderedComponentsWithType } from 'react-dom/test-utils';
import { setting } from "./setting.js";
function App() {
  const [data, setData] = useState("");
  const [image, setImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [currentL, setLayer] = useState(null);
  const canvas = useRef(null);
  const settingRef = useRef(null);
  const [layersOrder, setLayersOrder] = useState(setting);
  const [open, setOpen] = useState(false);
  const [config,setConfig] = useState(null);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if(config)
      setLayersOrder(config);
    setOpen(false);
  };
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "/front/src/download.js";
    script.async = true;
    document.body.appendChild(script);
    axios
    .get("https://scottfiskart.scottfisk.com/setting.json")
    .then(function (result) {
      // we got it!
      console.log(result.data);
      setLayersOrder(result.data);
    });
    onNext();
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      onNext();
    }, 9000);
    return () => clearInterval(interval);
  }, [data]);
  const onNext = () => {
    setLoading(true);
    // setImage(null)
    setImage(getLayer(9));
    setImage(getLayer(0));
    setLayer(0);
  };
  const getLayer = (layer) => {
    const _layer = layersOrder[layer];
    const rand = Math.random();
    let element =
      Math.floor(rand * _layer.number) < _layer.number ? _layer.name + '/' + Math.floor(rand * _layer.number) : null;

    return (element += '.png');
  };

  const onload = async (e) => {
    const ctx = canvas.current.getContext('2d');
    await ctx.drawImage(e.target, 0, 0, 2160, 3840)
    if (currentL < 10) {
      setLayer(currentL + 1);
      const layer = getLayer(currentL)
      setImage(layer);
    }

    if (currentL == 10) {
      setData(canvas.current.toDataURL());
      setLoading(false);
    }

  }
  const onSave = () => {
    const time = moment().format("YYYY-MM-DD hh:mm:ss Random");
    const fileName = time + '.png';
    download(data, fileName, 'image/png');
  }

  const showFile = async (e) => {
    e.preventDefault()
    const reader = new FileReader()
    reader.onload = async (e) => {
      const text = (e.target.result)

      setConfig(JSON.parse(text));
    };
    reader.readAsText(e.target.files[0])
  }
  return (
    <div className="App">
      <div style={{ display: 'flex', height: '90vh', justifyContent: 'center', alignItems: 'center' }}>
        {data ? <div style={{ height: "85vh" }}>
          <Card style={{ height: '100%' }}>
            <CardMedia
              component="img"
              image={data}
              height='100%'
              alt="green iguana"
            />
          </Card>
          <CardActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <LoadingButton
              onClick={onNext}
              loading={loading}
              variant="outlined"
              color="secondary"
              style={{ color: '#D3d3d3', borderColor: '#D3d3d3' }}
            >
              Next
            </LoadingButton>
            <Button
              onClick={onSave}
              variant="outlined"
              color="secondary"
              style={{ color: '#D3d3d3', marginRight: '10px', borderColor: '#D3d3d3' }}
            >
              Save
            </Button>
            <div>
              {/* <Button
                variant="outlined"
                ref = {settingRef}
                style={{ color: '#D3d3d3', borderColor: '#D3d3d3' }}
                onClick={handleClickOpen}>
                Setting
              </Button> */}
              <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Setting</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Please upload your config file.
                  </DialogContentText>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Background"
                    type="file"
                    fullWidth
                    variant="standard"
                    onChange={(e) => { showFile(e); }}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}
                    style={{ color: '#D3d3d3', borderColor: '#D3d3d3' }}
                  >Save</Button>
                </DialogActions>
              </Dialog>
            </div>
          </CardActions>
        </div> : <CircularProgress color="secondary" />}
      </div>
      <img onLoad={onload} src={process.env.PUBLIC_URL + image} style={{ display: 'none' }} />
      <canvas ref={canvas} width={2160} height={3840} style={{ display: 'none' }} />
    </div>
  );
}

export default App;
