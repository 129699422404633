
export const setting = [{ "name": "layer_0_background", "number": 16 },
{ "name": "layer_1_paper", "number": 11 },
{ "name": "layer_2_paper", "number": 11 },
{ "name": "layer_3_white paper", "number": 14 },
{ "name": "layer_4_red paper", "number": 20 },
{ "name": "layer_5_paper", "number": 14 },
{ "name": "layer_6_shape", "number": 14 },
{ "name": "layer_7_illustrations", "number": 25 },
{ "name": "layer_8_numbers", "number": 27 },
{ "name": "layer_9_top", "number": 2 }]
